import { request as axios } from '@/util/request';
import fileDownload from 'js-file-download';

const getUrl = require('baimahu/url-with-param');
const { VUE_APP_API: FCREST } = process.env;
const API = {
  customer: '/boss/user/profile', // 用户列表或详情，
  address: '/boss/user/address', // 用户详情的收货地址
  statistics: '/boss/user/order/statistics', // 订单统计
  purchase_history: '/boss/user/order', // 用户购买记录
  after_service_history: '/boss/user/order/refund', // 用户售后记录
  invoice: '/boss/user/order/invoice', // 用户开票记录
  collect: '/boss/user/collect', // 用户收藏列表
  export: `${FCREST}/boss/user/profile/export`, // 导出
  PhoneChangeLog: '/boss/user/log',
  coupon: '/boss/user/coupon', //用户优惠卷
  tag: '/boss/user/tag', // 用户标签
};

// 分页数据接口
export interface PaginationData<T> {
  current: number;
  detail: T[];
  last: number;
  limit: number;
  total: number;
}
// 用户数据接口
export interface CustomerData {
  avatar_url: string;
  consumption_amount: number;
  create_time: number;
  is_bind_wechat: number;
  is_bind_weibo: number;
  last_consumption_time: number;
  mobile: string;
  nickname: string;
  order_count: number;
  register_id: number;
  status: number;
  status_name: string;
  user_id: number;
  vip: string;
}
// 请求发送字段接口
export interface CustomerSendData {
  consumption_amount_start?: string | number;
  consumption_amount_end?: string | number;
  create_time_end?: string;
  create_time_start?: string;
  current?: number;
  limit?: number;
  last_consumption_time_end?: string;
  last_consumption_time_start?: string;
  order_count_end?: string;
  order_count_start?: string;
  search?: string;
  status?: number | string;
  is_export?: number;
}
// 初始化客户数据
export function initCustomerList(
  remote?: PaginationData<CustomerData>
): PaginationData<CustomerData> {
  remote = remote || ({} as PaginationData<CustomerData>);
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 10,
    total: remote.total || 0,
  };
}
// 获取客户列表
export async function getCustomerList(send_data?: CustomerSendData) {
  const url = getUrl(API.customer, send_data);
  return await axios.get(url);
}

// 用户详情数据接口
export interface CustomerResDetailData {
  avatar_url: string;
  consumption_amount: number;
  create_time: number;
  delete_time: number;
  id: number;
  is_bind_wechat: number;
  is_bind_weibo: number;
  last_consumption_time: number;
  mobile: string;
  nickname: string;
  order_count: number;
  update_time: number;
  user_id: number;
  openid: any[];
  age: number;
  sex: number;
  wechat_number: string;
  profession: string;
  description: string;
  vip_expired_time: number;
}
export interface CustomerDetailData {
  avatar_url: string;
  consumption_amount: number;
  create_time: number;
  delete_time: number;
  id: number;
  is_bind_wechat: number;
  is_bind_weibo: number;
  last_consumption_time: number;
  mobile: string;
  nickname: string;
  order_count: number;
  update_time: number;
  user_id: number;
  weibo_nickname: string;
  wechat_nickname: string;
  age: number;
  sex: number;
  wechat_number: string;
  profession: string;
  description: string;
  vip_expired_time: number;
}
// 初始化用户详情数据
export function initCustomerData(remote?: CustomerResDetailData): CustomerDetailData {
  let weibo_nickname = '';
  let wechat_nickname = '';
  remote = remote || ({} as CustomerResDetailData);
  if (remote.openid) {
    remote.openid.forEach(x => {
      if (x.type == 1) {
        wechat_nickname = x.nickname;
      } else if (x.type == 2) {
        weibo_nickname = x.nickname;
      }
    });
  }

  return {
    avatar_url: remote.avatar_url || '',
    consumption_amount: remote.consumption_amount || 0,
    create_time: remote.create_time || 0,
    delete_time: remote.delete_time || 0,
    id: remote.id || 0,
    is_bind_wechat: remote.is_bind_wechat || 0,
    is_bind_weibo: remote.is_bind_weibo || 0,
    last_consumption_time: remote.last_consumption_time || 0,
    mobile: remote.mobile || '',
    nickname: remote.nickname || '',
    order_count: remote.order_count || 0,
    update_time: remote.update_time || 0,
    user_id: remote.user_id || 0,
    weibo_nickname: weibo_nickname || '',
    wechat_nickname: wechat_nickname || '',
    age: remote.age || 0,
    sex: remote.sex || 1,
    wechat_number: remote.wechat_number || '',
    profession: remote.profession || '',
    description: remote.description || '',
    vip_expired_time: remote.vip_expired_time || 0,
  };
}
// 获取用户详情
export async function getCustomerDetail(register_id: number) {
  return await axios.get(`${API.customer}/${register_id}`);
}

// 用户地址数据接口
export interface CustomerAddress {
  area_id: number;
  area_name: string;
  city_id: number;
  city_name: string;
  create_time: number;
  default_time: number;
  detail: string;
  id: number;
  mobile: string;
  name: string;
  province_id: number;
  province_name: string;
  register_id: number;
  tag: string;
  update_time: number;
}
// 初始化用户地址数据
export function initCustomerAddress(remote?: PaginationData<CustomerAddress>) {
  remote = remote || ({} as PaginationData<CustomerAddress>);
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
// 获取用户详情的收货地址
export async function getCustomerAddress(register_id: number, current: number = 1, limit?: number) {
  const url = getUrl(API.address, {
    register_id,
    current,
    limit,
  });
  return await axios.get(url);
}

// 订单数据接口
export interface OrderStatisticsData {
  invoice_count: number;
  invoiced_amount: number;
  invoiced_count: number;
  order_count: number;
  paid_amount: number;
  paid_count: number;
  refund_count: number;
  refunded_count: number;
  order_count_month: number;
  refund_ratio: string;
}
// 初始化订单统计数据
export function initOrderStatistics(remote?: OrderStatisticsData): OrderStatisticsData {
  remote = remote || ({} as OrderStatisticsData);
  return {
    invoice_count: remote.invoice_count || 0,
    invoiced_amount: remote.invoiced_amount || 0,
    invoiced_count: remote.invoiced_count || 0,
    order_count: remote.order_count || 0,
    paid_amount: remote.paid_amount || 0,
    paid_count: remote.paid_count || 0,
    refund_count: remote.refund_count || 0,
    refunded_count: remote.refunded_count || 0,
    order_count_month: remote.order_count_month || 0,
    refund_ratio: remote.refund_ratio || '',
  };
}
// 订单统计
export async function getOrderStatistics(register_id: number) {
  return await axios.get(`${API.statistics}/${register_id}`);
}

// 客户购买记录数据接口
export interface PurchaseHistory {
  cancel_time: number;
  closed_time: number;
  confirmed_time: number;
  create_time: number;
  delivery_time: number;
  express_address: string;
  express_cost: number;
  express_mobile: string;
  express_name: string;
  express_number: string;
  goods: WareData[];
  goods_cost: number;
  id: number;
  is_sf: number;
  number: string;
  paid_time: number;
  pay_type: number;
  remark: string;
  status: number;
  status_name: string;
  update_time: number;
  user_id: number;
}
// 商品数据接口
export interface WareData {
  closing_unit_cost: number;
  count: number;
  create_time: number;
  id: number;
  image_url: string;
  order_id: number;
  product_id: number;
  refund_freeze_count: number;
  refund_success_count: number;
  title: string;
  update_time: number;
  warehouse_id: number;
  warehouse_name: string;
}
// 初始化购买记录数据
export function initPruchaseHistory(
  remote?: PaginationData<PurchaseHistory>
): PaginationData<PurchaseHistory> {
  remote = remote || ({} as PaginationData<PurchaseHistory>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
// 客户购买记录
export async function getPurchaseHistory(
  register_id: number,
  current: number = 1,
  limit: number = 5
) {
  const url = getUrl(API.purchase_history, {
    register_id,
    current,
    limit,
  });
  return await axios.get(url);
}

// 售后记录数据接口
export interface AfterServiceHistoryData {
  buyer_nickname: string;
  check_time: number;
  count: number;
  create_time: number;
  disagree_reason: string;
  disagree_reason_image: string;
  express_address: string;
  express_mobile: string;
  express_name: string;
  goods_image_url: string;
  goods_price: number;
  goods_title: string;
  id: number;
  order_goods_id: number;
  order_id: number;
  product_id: number;
  reason_detail: string;
  reason_image_url: string;
  reason_type: number;
  reject_reason: string;
  status: number;
  user_id: number;
  warehouse_id: number;
  warehouse_name: string;
}
// 初始化售后记录数据
export function initAfterServiceHistory(
  remote?: PaginationData<AfterServiceHistoryData>
): PaginationData<AfterServiceHistoryData> {
  remote = remote || ({} as PaginationData<AfterServiceHistoryData>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
// 客户售后记录
export async function getAfterServiceHistory(
  register_id: number,
  current: number = 1,
  limit: number = 5
) {
  const url = getUrl(API.after_service_history, {
    register_id,
    current,
    limit,
  });
  return await axios.get(url);
}

// 客户开票记录数据接口
export interface InvoiceHistory {
  amount: number;
  create_time: number;
  ein: string;
  email_address: string;
  id: number;
  order: OrderData;
  order_id: number;
  pass_time: number;
  title: string;
  type: number;
  user_id: number;
}
interface OrderData {
  cancel_time: number;
  closed_time: number;
  confirmed_time: number;
  create_time: number;
  delivery_time: number;
  express_address: string;
  express_cost: number;
  express_mobile: string;
  express_name: string;
  express_number: string;
  goods: WareData[];
  goods_cost: number;
  id: number;
  is_sf: number;
  number: string;
  paid_time: number;
  pay_type: number;
  remark: string;
  status: number;
  status_name: string;
  update_time: number;
  user_id: number;
}
// 初始化开票记录
export function initInvoiceHistory(
  remote?: PaginationData<InvoiceHistory>
): PaginationData<InvoiceHistory> {
  remote = remote || ({} as PaginationData<InvoiceHistory>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
// 客户开票记录
export async function getInvoiceHistory(register_id: number, current: number = 1) {
  const url = getUrl(API.invoice, {
    register_id,
    current,
  });
  return await axios.get(url);
}

// 收藏列表数据接口
export interface CollectData {
  product_id: number;
  title: string;
  is_show: number;
  price: number;
  image_url: string;
  create_time: number;
  paid: number;
}
// 初始化用户列表数据
export function initCollectData(remote?: PaginationData<CollectData>): PaginationData<CollectData> {
  remote = remote || ({} as PaginationData<CollectData>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  };
}
// 获取用户收藏列表
export async function getCollectList(register_id: number, current: number = 1, limit: number = 5) {
  const url = getUrl(API.collect, {
    register_id,
    current,
    limit,
  });
  return await axios.get(url);
}

// 导出
export async function exportData(query_params: any) {
  const url = getUrl(API.export, { ...query_params });
  return new Promise((resolve, reject) => {
    axios
      .get(`https:${url}`, { responseType: 'blob' })
      .then(res => {
        fileDownload(res as any, '客户列表.xlsx');
        resolve(res);
      })
      .catch(() => {
        reject(url);
      });
  });
}
interface RequestParam {
  register_id: number;
}
export interface PhoneLog {
  create_time: number;
  content: string;
  id: number;
  register_id: number;
  type: number;
  update_time: number;
}
export async function getPhonelog(query_params: RequestParam) {
  const url = getUrl(API.PhoneChangeLog, { ...query_params });
  return await axios.get(url);
}
export interface PutProfileParams {
  nickname: string;
  age: number;
  sex: number;
  wechat_number: string;
  profession: string;
  description: string;
}
export async function putProfile(id: number, params: PutProfileParams) {
  return await axios.put(`/boss/user/profile/${id}`, params);
}
// 获取优惠卷
export async function getCoupon(register_id: number, current: number = 1) {
  const url = getUrl(API.coupon, {
    register_id,
    current,
  });
  return await axios.get(url);
}
export function initCouponData(remote?: PaginationData<any>): PaginationData<any> {
  remote = remote || ({} as PaginationData<any>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  };
}
// 获取客户标签
export async function getCustomerTags(query_params: any) {
  const url = getUrl(API.tag, { ...query_params });
  return await axios.get(url);
}

// 新建客户标签
export async function addCustomerTags(params: { name: string; type: number; rule: any }) {
  const url = `/boss/user/tag`;
  return await axios.post(url, params);
}

// 删除客户标签
export async function deleteCustomerTags(params) {
  const url = '/boss/user/tag/0';
  return await axios.delete(url, { data: params });
}

// 编辑手动标签
export async function editManualTags(id, params) {
  const url = `/boss/user/register/tag/${id}`;
  try {
    const res = await axios.put(url, params);
    if (res.status === 200 && res.data) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
}
